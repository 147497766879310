import React, { useEffect, useRef, useState } from "react";
import { getSnapshot } from "mobx-state-tree";
import { mstGeneral } from "../../mobx";
import emailjs from "@emailjs/browser";
import { observer } from "mobx-react";
import ReCAPTCHA from "react-google-recaptcha";

const {
  REACT_APP_EMAILJS_PUBLIC_KEY,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_TEMPLATE_ID_USER,
  REACT_APP_EMAILJS_TEMPLATE_ID_ADMIN,
  REACT_APP_RECAPTCHA_SITE_KEY,
} = process.env;

// Make the component an observer
const ContactUs = observer(() => {
  const recaptchaRef = useRef(null);

  // Initialize EmailJS with your public key
  useEffect(() => {
    emailjs.init(REACT_APP_EMAILJS_PUBLIC_KEY);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    type: "enquiry",
    message: "",
    agreeToTerms: false,
  });
  const [status, setStatus] = useState({ type: "", message: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Translation helper
  const t = (en, my) =>
    getSnapshot(mstGeneral).currentLang === "en" ? en : my;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.agreeToTerms) {
      setStatus({
        type: "error",
        message: translations.terms.error,
      });
      return;
    }

    // Get reCAPTCHA token
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setStatus({
        type: "error",
        message: t(
          "Please complete the reCAPTCHA verification.",
          "Sila lengkapkan pengesahan reCAPTCHA."
        ),
      });
      return;
    }

    setIsSubmitting(true);
    setStatus({ type: "", message: "" });

    try {
      // Add recaptcha token to both email templates
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        message_type: t(
          {
            enquiry: "General Enquiry",
            feedback: "Feedback",
            suggestion: "Suggestion",
          }[formData.type],
          {
            enquiry: "Pertanyaan Umum",
            feedback: "Maklum Balas",
            suggestion: "Cadangan",
          }[formData.type]
        ),
        message: formData.message,
        year: new Date().getFullYear(),
        "g-recaptcha-response": recaptchaValue,
      };

      // Send email to admin with params
      const adminEmail = await emailjs.send(
        REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID_ADMIN, // Template for admin
        templateParams,
        {
          from_name: formData.name,
          from_email: formData.email,
          message_type: t(
            {
              enquiry: "General Enquiry",
              feedback: "Feedback",
              suggestion: "Suggestion",
            }[formData.type],
            {
              enquiry: "Pertanyaan Umum",
              feedback: "Maklum Balas",
              suggestion: "Cadangan",
            }[formData.type]
          ),
          message: formData.message,
          year: new Date().getFullYear(),
        },
        REACT_APP_EMAILJS_PUBLIC_KEY
      );

      // Send confirmation email to user
      const userEmail = await emailjs.send(
        REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID_USER, // Template for user
        {
          to_name: formData.name,
          to_email: formData.email,
          message_type: t(
            {
              enquiry: "General Enquiry",
              feedback: "Feedback",
              suggestion: "Suggestion",
            }[formData.type],
            {
              enquiry: "Pertanyaan Umum",
              feedback: "Maklum Balas",
              suggestion: "Cadangan",
            }[formData.type]
          ),
          message: formData.message,
          year: new Date().getFullYear(),
        },
        REACT_APP_EMAILJS_PUBLIC_KEY
      );

      if (adminEmail.status === 200 && userEmail.status === 200) {
        // Reset recaptcha after successful submission
        recaptchaRef.current.reset();

        setStatus({
          type: "success",
          message: t(
            "Message sent successfully! Please check your email for confirmation.",
            "Mesej berjaya dihantar! Sila semak emel anda untuk pengesahan."
          ),
        });
        // Reset form
        setFormData({
          name: "",
          email: "",
          type: "enquiry",
          message: "",
        });
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus({
        type: "error",
        message: t(
          "Failed to send message. Please try again.",
          "Gagal menghantar mesej. Sila cuba lagi."
        ),
      });
    } finally {
      // Reset recaptcha in all cases
      recaptchaRef.current.reset();
      setIsSubmitting(false);
    }
  };

  // Generic handle change for all inputs including checkbox
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Translation objects for form content
  const translations = {
    title: t("Contact Us", "Hubungi Kami"),
    subtitle: t(
      "We'd love to hear from you! Please fill out the form below.",
      "Kami ingin mendengar dari anda! Sila isi borang di bawah."
    ),
    labels: {
      name: t("Name", "Nama"),
      email: "Email",
      type: t("Type of Message", "Jenis Mesej"),
      message: t("Message", "Mesej"),
    },
    options: {
      enquiry: t("General Enquiry", "Pertanyaan Umum"),
      feedback: t("Feedback", "Maklum Balas"),
      suggestion: t("Suggestion", "Cadangan"),
    },
    button: {
      sending: t("Sending...", "Menghantar..."),
      send: t("Send Message", "Hantar Mesej"),
    },
    terms: {
      checkbox: t(
        "I agree to the Terms of Use and Privacy Policy",
        "Saya bersetuju dengan Terma dan Polisi Privasi"
      ),
      error: t(
        "Please agree to the Terms and Privacy Policy",
        "Sila setuju dengan Syarat dan Polisi Privasi"
      ),
    },
  };

  return (
    <div className="w-full max-w-2xl mx-auto py-6 md:py-8">
      <h1 className="text-4xl text-center md:text-left font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-600">
        {translations.title}
      </h1>
      <p className="text-white mb-8 text-center md:text-left">
        {translations.subtitle}
      </p>

      <div className="max-w-lg mb-6">
        {" "}
        {/* Adjust height as needed */}
        {status.message && (
          <div
            className={`p-4 text-xs rounded-md ${
              status.type === "success"
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {status.message}
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit} className="space-y-6 mx-auto">
        <div className="flex flex-col gap-2">
          <label htmlFor="name" className="font-medium text-white">
            {translations.labels.name}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="font-medium text-white">
            {translations.labels.email}
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="type" className="font-medium text-white">
            {translations.labels.type}
          </label>
          <select
            id="type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
          >
            <option value="enquiry">{translations.options.enquiry}</option>
            <option value="feedback">{translations.options.feedback}</option>
            <option value="suggestion">
              {translations.options.suggestion}
            </option>
          </select>
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="message" className="font-medium text-white">
            {translations.labels.message}
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="5"
            required
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-y"
          />
        </div>

        <div className="flex items-start gap-2">
          <input
            type="checkbox"
            id="agreeToTerms"
            name="agreeToTerms"
            checked={formData.agreeToTerms}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                agreeToTerms: e.target.checked,
              }))
            }
            className="mt-1"
          />
          <label htmlFor="agreeToTerms" className="text-sm text-white">
            {translations.terms.checkbox}{" "}
            {/* <span className="text-yellow-400 hover:text-yellow-300 underline">
              {t("Terms of Use", "Terma")}
            </span>{" "}
            {t("and", "dan")}{" "}
            <span className="text-yellow-400 hover:text-yellow-300 underline">
              {t("Privacy Policy", "Dasar Privasi")}
            </span> */}
          </label>
        </div>

        {/* ReCAPTCHA */}
        <div className="flex justify-center md:justify-start -mx-4 md:mx-0 overflow-hidden">
          <div className="transform scale-[0.85] md:scale-90 origin-center md:origin-left">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-yellow-400 text-black font-semibold py-3 px-6 rounded-md hover:bg-yellow-200 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting
            ? translations.button.sending
            : translations.button.send}
        </button>
      </form>
    </div>
  );
});

export default ContactUs;
