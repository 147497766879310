import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { mstGeneral } from "../mobx";

const MENU_ITEMS = [
  { id: 1, slug: "home", url: "/", name_en: "Home", name_my: "Utama" },
  {
    id: 2,
    slug: "contact-us",
    url: "/contact-us",
    name_en: "Contact Us",
    name_my: "Hubungi Kami",
  },
  {
    id: 3,
    slug: "privacy-policy",
    url: "/privacy-policy",
    name_en: "Privacy Policy",
    name_my: "Polisi Privasi",
  },
  {
    id: 4,
    slug: "terms-of-use",
    url: "/terms-of-use",
    name_en: "Terms Of Use",
    name_my: "Terma",
  },
  {
    id: 5,
    slug: "tournament-registration",
    url: "https://www.todakarena.com/selangorfuturecup/register/",
    name_en: "Register",
    name_my: "Daftar",
  },
];

const Header = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [menuOpen, setMenuOpen] = useState(false);

  const isActive = (url) => currentUrl === url;

  const onClick = (url) => {
    setMenuOpen(false); // Close menu on navigation
    navigate(url);
  };

  const onChangeLang = (lang) => {
    mstGeneral.onChangeLang({ lang });
  };

  return (
    <header className="w-full md:bg-transparent bg-transparent text-white">
      <div className="container mx-auto px-4 py-3 md:p-6 flex gap-5 justify-center md:justify-between items-center">
        {/* Logo */}
        <a
          className="items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-3xl"
          href="/"
        >
          <img
            src="todak-arena-logo-min.png"
            className="w-16 h-16"
            alt="logo"
          />
        </a>

        {/* Navigation Menu (Desktop View) */}
        <nav
          className={`${
            menuOpen ? "block" : "hidden"
          } md:flex md:items-center md:gap-4 w-full md:w-auto md:bg-transparent`}
        >
          <div
            className={`container mx-auto gap-5 ${
              menuOpen
                ? "p-4 flex flex-col" // Mobile View
                : "p-6 flex justify-between items-center" // Desktop View
            }`}
          >
            {/* Navigation Links */}
            <ul
              className={`flex flex-col md:flex-row md:items-center md:gap-6 ml-auto ${
                menuOpen ? "mt-4" : ""
              }`}
            >
              {MENU_ITEMS.filter(
                (item) => item.slug !== "tournament-registration"
              ).map((item) => (
                <li key={item.id}>
                  <button
                    className={`block px-4 py-2 rounded cursor-pointer ${
                      isActive(item.url)
                        ? "bg-gray-600/30"
                        : "hover:bg-gray-700 md:hover:bg-gray-600/30"
                    }`}
                    onClick={() => onClick(item.url)}
                  >
                    {getSnapshot(mstGeneral).currentLang === "en"
                      ? item.name_en
                      : item.name_my}
                  </button>
                </li>
              ))}
            </ul>

            {/* Language Switch */}
            <div className="flex justify-center md:justify-start items-center text-white mb-0 mt-0 md:mr-8">
              <div
                onClick={() => {
                  onChangeLang("my");
                }}
                style={{
                  opacity:
                    getSnapshot(mstGeneral).currentLang !== "en" ? 1 : 0.5,
                }}
                className="cursor-pointer"
              >
                <div className="border-r-2 pr-4">
                  <img
                    src="https://cdn-a.todakarena.com/public/flag-of-Malaysia.png"
                    className="h-4"
                    alt="malaysia"
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  onChangeLang("en");
                }}
                style={{
                  opacity:
                    getSnapshot(mstGeneral).currentLang === "en" ? 1 : 0.5,
                }}
                className="cursor-pointer"
              >
                <div className="pl-4">
                  <img
                    src="https://cdn-a.todakarena.com/public/flag-of-United-Kingdom.png"
                    className="h-4"
                    alt="united-kingdom"
                  />
                </div>
              </div>
            </div>

            {/* Tournament Registration Button (Desktop View) */}
            {/* <div className="mt-4 md:mt-0 ml-auto">
              <a
                href={
                  MENU_ITEMS.find(
                    (item) => item.slug === "tournament-registration"
                  ).url
                }
                target="_blank"
                rel="noopener noreferrer"
                className="block bg-[#FBBC05] hover:bg-[#e5b940] text-black font-bold py-2 px-4 rounded text-center"
              >
                {getSnapshot(mstGeneral).currentLang === "en"
                  ? MENU_ITEMS.find(
                      (item) => item.slug === "tournament-registration"
                    ).name_en
                  : MENU_ITEMS.find(
                      (item) => item.slug === "tournament-registration"
                    ).name_my}
              </a>
            </div> */}
          </div>
        </nav>

        {/* Tournament Registration Button (Mobile View) */}
        {/* <div className="md:mt-0 ml-auto md:hidden">
          <a
            href={
              MENU_ITEMS.find((item) => item.slug === "tournament-registration")
                .url
            }
            target="_blank"
            rel="noopener noreferrer"
            className="block bg-[#FBBC05] hover:bg-[#e5b940] text-black font-bold py-2 px-4 rounded text-center"
          >
            {getSnapshot(mstGeneral).currentLang === "en"
              ? MENU_ITEMS.find(
                  (item) => item.slug === "tournament-registration"
                ).name_en
              : MENU_ITEMS.find(
                  (item) => item.slug === "tournament-registration"
                ).name_my}
          </a>
        </div> */}
      </div>
    </header>
  );
});

export default Header;
