import { types, flow, getSnapshot } from "mobx-state-tree";
import axios from "axios";
import persist from "mst-persist";

// const BASE_URL = "http://192.168.31.242:8000/api/"
const BASE_URL = "https://staging-api.todakarena.com/api/";
// const BASE_URL = "https://api.todakarena.com/api/"

const General = types
  .model({
    currentLang: types.optional(types.string, "en"),
  })
  .actions((self) => {
    const fetchAbout = flow(function* (params) {
      const url = BASE_URL + `master/about/about-${params.lang}`;
      try {
        const response = yield axios.get(url);
        console.log("mobx fetchAbout ===> ", response);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchPrivacy = flow(function* () {
      const url = BASE_URL + "master/privacy";
      try {
        const response = yield axios.get(url);
        console.log("mobx fetchPrivacy ===> ", response);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const fetchTerms = flow(function* () {
      const url = BASE_URL + "master/terms";
      try {
        const response = yield axios.get(url);
        console.log("mobx fetchTerms ===> ", response);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const onChangeLang = flow(function* (params) {
      self.currentLang = params.lang;
    });

    return { fetchAbout, fetchPrivacy, fetchTerms, onChangeLang };
  });

export const mstGeneral = General.create({});

if (typeof window !== "undefined") {
  persist("mstGeneral", mstGeneral, {
    storage: window.localStorage,
    jsonify: true,
  });
}
