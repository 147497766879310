import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { mstGeneral } from "../mobx";
import { IoCloseOutline } from "react-icons/io5";
import React, { useState, useEffect, useRef } from "react";

const TournamentPopup = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const currentLang = getSnapshot(mstGeneral).currentLang;
  const popupRef = useRef(null); // Create a ref for the popup

  useEffect(() => {
    // Show popup after a short delay when component mounts
    const timer = setTimeout(() => setIsOpen(true), 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Function to handle clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false); // Close the popup
      }
    };

    // Add event listener to document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]); // Re-run effect when isOpen changes

  const CONTENT = {
    title: {
      en: "Selangor State School MLBB E-Sports Championship (U17)",
      my: "KEJOHANAN E-SUKAN MLBB (U17) ANTARA SEKOLAH NEGERI SELANGOR",
    },
    subtitle: {
      en: "Selangor Future Cup MLBB 2024",
      my: "SELANGOR FUTURE CUP MLBB 2024",
    },
    prize: {
      en: "Total Prize Pool Amounting to RM 10,000!",
      my: "Hadiah Terkumpul Berjumlah RM 10,000!",
    },
    description: {
      en: "Selangor State School MLBB E-Sports Championship (U17): Selangor Future Cup MLBB 2024",
      my: "KEJOHANAN E-SUKAN MLBB (U17) ANTARA SEKOLAH NEGERI SELANGOR SELANGOR FUTURE CUP MLBB 2024",
    },
    button: {
      en: "Register Now",
      my: "Daftar Sekarang",
    },
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center z-50 p-4 rounded-xl">
      <div
        ref={popupRef}
        className="relative w-full max-w-xl md:max-w-xl bg-[#0A0A2E] text-white overflow-hidden rounded-xl"
      >
        {/* Close button */}
        <button
          onClick={() => setIsOpen(false)}
          className="absolute right-4 top-4 text-white hover:text-gray-300 z-10"
        >
          <IoCloseOutline className="h-6 w-6" />
        </button>

        {/* CONTENT INSIDE */}
        {/* Background with overlay gradient */}
        <div className="absolute inset-0 rounded-xl overflow-hidden">
          <div className="absolute inset-0 flex">
            <div className="relative h-full w-full rounded-xl overflow-hidden">
              {/* Left half - Dark blue gradient matching the image */}

              {/* Right half - Image */}
              {/* Image background */}
              <div
                className="absolute inset-0 bg-cover bg-center"
                style={{
                  backgroundImage:
                    "url('https://cdn-main.todak.com/todakarena/todak-arena-tournament-popup.png')",
                }}
              >
                <div className="w-1/2 bg-gradient-to-br from-[#020420] via-[#101542] to-[#1a237e]/90" />
              </div>
              <div className="absolute inset-0 bg-gradient-to-r from-[#020420]/90 via-[#101542]/70 to-transparent"></div>

              {/* Gradient overlay */}
            </div>
            {/* <div
              className="w-1/2 bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: "url('/todak-arena-tournament-popup.png')",
              }}
            /> */}
          </div>
          {/* Overlay to blend them together */}
          <div className="absolute inset-0 bg-gradient-to-b md:bg-gradient-to-r from-[#101542]/90 via-[#101542]/80 to-[#101542]/0" />
        </div>

        {/* Content */}
        <div className="relative z-1 p-8 md:p-10 flex flex-col items-center md:items-start justify-center">
          {/* Logo */}
          <div className="mb-8">
            <img
              src="/todak-arena-selangor-cup.png"
              alt="Tournament Logo"
              className="w-60 h-auto object-contain"
            />
          </div>

          {/* Text Content */}
          <div className="max-w-xs flex flex-col gap-8 text-center md:text-left">
            {/* <h2 className="text-2xl font-bold">
              KEJOHANAN E-SUKAN MLBB (U17) ANTARA SEKOLAH NEGERI SELANGOR
              SELANGOR FUTURE CUP MLBB 2024
            </h2> */}
            <h2 className="text-lg md:text-xl font-bold uppercase">
              {CONTENT.title[currentLang]}
            </h2>
            <p className="text-sm md:text-base font-semibold text-gray-300 uppercase">
              {CONTENT.subtitle[currentLang]}
            </p>
            <div className="space-y-2">
              {/* <p className="text-[25px] font-bold text-white">
                {CONTENT.prize[currentLang]}
              </p> */}

              {/* <p className="text-[25px] font-bold text-white">
                {CONTENT.description[currentLang]}
              </p> */}

              {/* <p className="text-base text-white">
                {CONTENT.description[currentLang]}
              </p> */}
            </div>

            {/* Registration Button */}
            <div>
              <a
                href="https://www.todakarena.com/selangorfuturecup/register/"
                target="_blank"
                rel="noreferrer"
                className="inline-block bg-yellow-400 text-[#1e1e1e] font-semibold px-8 py-3 rounded-lg hover:bg-yellow-300 transition-colors"
              >
                {CONTENT.button[currentLang]}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TournamentPopup;
