import moment from "moment";
import { useEffect, useState } from "react";
import { mstGeneral } from "../../mobx";
import { IoLanguage } from 'react-icons/io5';

const About = () => {
  const [isEng, setIsEng] = useState(true)
  const [aboutMyData, setAboutMyData] = useState({})
  const [aboutEnData, setAboutEnData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    mstGeneral.fetchAbout({ lang: 'my' })
    .then((response) => {
      setAboutMyData(response)
      setIsLoading(false)
    })
    .catch((error) => {
      setIsLoading(false)
    })
    mstGeneral.fetchAbout({ lang: 'en' })
    .then((response) => {
      setAboutEnData(response)
      setIsLoading(false)
    })
    .catch((error) => {
      setIsLoading(false)
    })
  }, [])

  const onChangeLang = (lang) => {
    setIsEng(lang === 'en')
  }

  const aboutData = isEng ? aboutEnData : aboutMyData

  return (
    <div className="w-full pt-4">
      <div className="flex justify-between align-center">
        <div className="text-xl font-bold text-white text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-600">About</div>
        <div className="text-white">
          <div className="flex items-center">
            <IoLanguage size={25} className="mr-4" />
            <a onClick={() => { onChangeLang("my") }} className={`cursor-pointer opacity-${!isEng ? '100' : '20'} hover:opacity-100`}>
              <div className="border-r-2 pr-4"><img src="https://www.countries-ofthe-world.com/flags-normal/flag-of-Malaysia.png" className="h-4" /></div>
            </a>
            <a onClick={() => { onChangeLang("en") }} className={`cursor-pointer opacity-${isEng ? '100' : '20'} hover:opacity-100`}>
              <div className="pl-4"><img src="https://www.countries-ofthe-world.com/flags-normal/flag-of-United-States-of-America.png" className="h-4" /></div>
            </a>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="text-white mb-5 w-full" style={{ height: '50vh' }} dangerouslySetInnerHTML={{__html: aboutData.content}} />
        <div className="text-sm text-gray-600">Last updated on {moment(aboutData.updated_at).format("DD-MM-YYYY hh:mm A")}</div>
      </div>
    </div>
  )
}

export default About;