import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import moment from "moment";
import { useEffect, useState } from "react";
import { mstGeneral } from "../../mobx";

const Privacy = observer(() => {
  const [privacyData, setPrivacyData] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    mstGeneral.fetchPrivacy()
    .then((response) => {
      setPrivacyData(response)
      setIsLoading(false)
    })
    .catch((error) => {
      setIsLoading(false)
    })
  }, [])

  const pageTitle = getSnapshot(mstGeneral).currentLang === 'en' ? "Privacy Policy" : "Polisi Privasi"

  return (
    <div className="w-full pt-4">
      <div className="text-xl font-bold text-white text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-600">{pageTitle}</div>
      <div className="mt-10">
        <div className="text-white mb-5 overflow-y-scroll w-full scrollbar" style={{ height: '50vh' }} dangerouslySetInnerHTML={{__html: privacyData.content}} />
        {/* <div className="text-sm text-gray-600">Last updated on {moment(privacyData.updated_at).format("DD-MM-YYYY hh:mm A")}</div> */}
      </div>
    </div>
  )
})

export default Privacy;