import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { mstGeneral } from "../mobx";

const MENU_ITEMS = [
  { id: 1, slug: "home", url: "/", name_en: "Home", name_my: "Utama" },
  {
    id: 2,
    slug: "contact-us",
    url: "/contact-us",
    name_en: "Contact Us",
    name_my: "Hubungi Kami",
  },
  {
    id: 3,
    slug: "privacy-policy",
    url: "/privacy-policy",
    name_en: "Privacy Policy",
    name_my: "Polisi Privasi",
  },
  {
    id: 4,
    slug: "terms-of-use",
    url: "/terms-of-use",
    name_en: "Terms Of Use",
    name_my: "Terma",
  },
];

const Footer = observer(() => {
  const onChangeLang = (lang) => {
    mstGeneral.onChangeLang({ lang });
  };

  const currentLang = getSnapshot(mstGeneral).currentLang;

  return (
    <>
      <div className="w-full pt-16 pb-6 text-sm text-center fade-in flex flex-col gap-3 md:flex-row justify-center items-center">
        <div className="grid md:hidden grid-cols-2 items-center w-auto gap-x-3">
          {/* Home Link */}
          <a className="text-white no-underline hover:no-underline" href="/">
            {currentLang === "en"
              ? MENU_ITEMS.find((item) => item.slug === "home").name_en
              : MENU_ITEMS.find((item) => item.slug === "home").name_my}
          </a>

          {/* Contact Us Link */}
          <a
            className="text-white no-underline hover:no-underline"
            href="/contact-us"
          >
            {currentLang === "en"
              ? MENU_ITEMS.find((item) => item.slug === "contact-us").name_en
              : MENU_ITEMS.find((item) => item.slug === "contact-us").name_my}
          </a>

          {/* ">
            {/* Privacy Policy Link */}
          <a
            className="text-white no-underline hover:no-underline"
            href="/privacy-policy"
          >
            {currentLang === "en"
              ? MENU_ITEMS.find((item) => item.slug === "privacy-policy")
                  .name_en
              : MENU_ITEMS.find((item) => item.slug === "privacy-policy")
                  .name_my}
          </a>

          {/* Terms of Use Link */}
          <a
            className="text-white no-underline hover:no-underline"
            href="/terms-of-use"
          >
            {currentLang === "en"
              ? MENU_ITEMS.find((item) => item.slug === "terms-of-use").name_en
              : MENU_ITEMS.find((item) => item.slug === "terms-of-use").name_my}
          </a>
        </div>

        <p className="text-white no-underline hover:no-underline">
          {new Date().getFullYear()} &copy; Todak Arena. All Rights Reserved.
        </p>

        {/* Language Switch */}
        <div className="flex md:hidden justify-center items-center text-white mb-0 mt-0 md:mr-8">
          <div
            onClick={() => onChangeLang("my")}
            style={{
              opacity: currentLang !== "en" ? 1 : 0.5,
            }}
            className="cursor-pointer"
          >
            <div className="border-r-2 pr-4">
              <img
                src="https://cdn-a.todakarena.com/public/flag-of-Malaysia.png"
                className="h-4"
                alt="malaysia"
              />
            </div>
          </div>
          <div
            onClick={() => onChangeLang("en")}
            style={{
              opacity: currentLang === "en" ? 1 : 0.5,
            }}
            className="cursor-pointer"
          >
            <div className="pl-4">
              <img
                src="https://cdn-a.todakarena.com/public/flag-of-United-Kingdom.png"
                className="h-4"
                alt="united-kingdom"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Footer;
